<template>
  <div>
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="输入姓名，手机，邮箱，公司"
          ></el-input>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select
            placeholder="类型"
            v-model="form_2.type"
            size="medium"
          >
            <el-option label="全部" :value="-1"></el-option>

            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.type"
              :key="index"
            ></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select placeholder="行业" v-model="form_2.business" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
            ></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select placeholder="小助手" v-model="form_2.waId" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in $store.state.NormalList"
              :key="item.id"
            ></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 355px">
          <label for="">城市</label>
          <el-select
            placeholder="省份"
            v-model="form_2.province"
            size="medium"
            style="width: 150px"
            class="ml"
            @change="getc(form_2.province)"
          >
            <el-option label="全部" :value="-1"></el-option>

            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in p_arr"
              :key="item.code"
            ></el-option>
          </el-select>
          <el-select
            placeholder="城市"
            v-model="form_2.city"
            size="medium"
            style="width: 150px"
            class="ml"
          >
            <el-option label="全部" :value="-1"></el-option>

            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in c_arr"
              :key="item.code"
            ></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 35px;">
          <el-button @click="reset" size="small">重置</el-button>
        </nav>
        <nav class="input" style="width: 35px">
          <el-button @click="subimt" type="primary" size="small">{{
            '搜索'
          }}</el-button>
        </nav>
        <nav class="input" style="width: 65px">
          <el-button
            @click="beforedown"
            type="primary"
            size="small"
            v-show="$store.state.btn_if.indexOf('user_guest_down_excel') != -1"
            >{{ btn ? '导出Excel' : '导出中..' }}</el-button
          >
        </nav>

        <nav class="input" style="width: 65px">
          <el-button
            type="primary"
            size="small"
            @click="Import"
            v-show="
              $store.state.btn_if.indexOf('user_guest_import_excel') != -1
            "
            >{{ impEX }}</el-button
          >
        </nav>
        <nav class="input" style="width: 83px">
          <el-button
            type="primary"
            size="small"
            @click="show_s"
            v-show="$store.state.btn_if.indexOf('user_guest_redundant') != -1"
            >{{ reveal ? '显示冗余数据' : '隐藏冗余数据' }}</el-button
          >
        </nav>
        <nav class="input" style="width: 60px">
          <router-link to="/user_guest_edit">
            <el-button
              type="primary"
              size="small"
              v-show="$store.state.btn_if.indexOf('user_guest_add') != -1"
              >添加会员</el-button
            ></router-link
          >
        </nav>
        <nav class="input" style="width: 60px">
        <el-badge :value="total_form.allTotal" class="item" type="primary">
        <el-button size="small">总数量</el-button>
        </el-badge>
        </nav>
        <nav class="input" style="width: 150px;margin-left:35px">
         <el-badge :value="total_form.existPhoneTotal" class="item" type="primary">
        <el-button size="small">手机号不为空总数量</el-button>
        </el-badge>
        </nav>

      </nav>
    </el-card>
    <el-dialog title="" :visible.sync="dioA" width="30%" @close="">
      <Show @form="checkf"></Show>
      <span slot="footer">
        <el-button @click="dioA = false">取消</el-button>
        <el-button type="primary" @click="download">{{
          btn ? '导出' : '导出中..'
        }}</el-button>
      </span>
    </el-dialog>

    <el-card style="margin-top: 10px;" v-show="reveal">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="realName" label="姓名">
           <template slot-scope="{ row }">
            {{ row.realName }}
            <el-tag
              type="danger"
              size="mini"
              effect="dark"
              @click="unfold(row)"
            >
              {{ row.num }}</el-tag
            >
          </template>
           </el-table-column>
        <!-- <el-table-column prop="desc" label="简介"> </el-table-column> -->
        <el-table-column prop="company" label="公司"> </el-table-column>
        <!-- <el-table-column prop="job" label="职务对外名称"> </el-table-column> -->
        <el-table-column prop="phone" label="手机号" width="130">
          <template slot-scope="{ row }">
            {{ row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="130">
          <template slot-scope="{ row }">
            <!-- {{ row.type | Ftype }} -->
            <!-- {{row.type}} -->
            <el-select v-model="row.type" multiple @change="Stype(row)">
              <el-option v-for="(item,i) in $store.state.config.type"
                :key="i"
                :label="item"
                :value="i">
              </el-option>
            </el-select>
            
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="行业" width="130">
          <template slot-scope="{ row }">
            <el-select placeholder="行业" v-model="row.business"  @change="Sbusiness(row)" multiple :multiple-limit='3'>
            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
            ></el-option>
          </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位">
            <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai_1"
                v-model="row.job"
                size="small"
                class="id"
                :autofocus="row.pai_1"
                @blur="handleInputBlur_1(row)"
              />
            </div>
            <span
              v-show="!row.pai_1"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row)
              "
            >
              {{ row.job }}</span
            >
            <i
              v-show="!row.pai_1"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row)
              "
            />
          </template>  
          
         </el-table-column>
        <el-table-column prop="jobLevelName" label="级别">
          <template slot-scope="{row}">
            <el-select v-model="row.jobLevel"  @change="S_jobLevel(row)">
              <el-option v-for="(item,index) in $store.state.config.userJobLevel"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
            
          </template>
        </el-table-column>
        <el-table-column prop="cityName" label="城市">
          <template slot-scope="{ row }">
             <span>{{row.provinceName}}</span>-<span>{{row.cityName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="活动" width="210">
        <template slot-scope="{ row }">
            <el-tag
              type="info"
              size="mini"
              effect="dark"
              v-for="item in !!row.activityName&&row.activityName.split(',')"
              :key="item"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                v-model="row.remark"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row)
              "
            >
              {{ row.remark }}</span
            >
            <i
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row)
              "
            />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="activityName" label="活动"> </el-table-column> -->
        <el-table-column prop="waId" label="小助手">
          <template slot-scope="{ row }">
            <el-select
              v-model="row.waId"
              placeholder="小助手"
              multiple
              :multiple-limit="3"
              size="medium"
              @change="chagne(row)"
              :disabled="!QX('user_guest_assistant')"
            >
              <el-option
                :label="item.name"
                :value="item.id + ''"
                v-for="(item, index) in $store.state.NormalList"
                :key="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="时间"> </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="ew">
            <router-link :to="'/user_guest_edit?id=' + ew.row.id">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="mini"
                @click="edit(ew.row.id)"
                class="skyblue"
                v-show="$store.state.btn_if.indexOf('user_guest_edit') != -1"
                >编辑</el-button
              >
            </router-link>

            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.id, 1)"
              class="red"
              v-show="$store.state.btn_if.indexOf('user_guest_del') != -1"
              >删除</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-camera"
              @click="information(ew.row)"
              class="green"
              v-show="$store.state.btn_if.indexOf('user_guest_show') != -1"
              >查看信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-card style="margin-top: 10px;" v-show="!reveal">
      <el-table
        :data="table"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
        @expand-change="show_ex"
      >
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="realName" label="姓名">
        <template slot-scope="{ row }">
            {{ row.realName }}
            <el-tag
              type="danger"
              size="mini"
              effect="dark"
              @click="unfold(row)"
            >
              {{ row.num }}</el-tag
            >
          </template>
        </el-table-column>
          
           </el-table-column>
        <!-- <el-table-column prop="desc" label="简介"> </el-table-column> -->
        <el-table-column prop="company" label="公司"> </el-table-column>
        <!-- <el-table-column prop="job" label="职务对外名称"> </el-table-column> -->
        <el-table-column prop="phone" label="手机号" width="130">
          <template slot-scope="{ row }">
            {{ row.phone }}

          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="130">
          <template slot-scope="{ row }">
            <!-- {{ row.type | Ftype }} -->
            <el-select v-model="row.type" @change="Stype(row,2)" multiple>
              <el-option v-for="(item,i) in $store.state.config.type"
                :key="i"
                :label="item"
                :value="i">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="行业" width="130">
          <template slot-scope="{ row }">
            <el-select placeholder="行业" v-model="row.business"  @change="Sbusiness(row,2)" multiple :multiple-limit='3'>
            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
            ></el-option>
          </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位">
                      <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai_1"
                v-model="row.job"
                size="small"
                class="id"
                :autofocus="row.pai_1"
                @blur="handleInputBlur_1(row,2)"
              />
            </div>
            <span
              v-show="!row.pai_1"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row,2)
              "
            >
              {{ row.job }}</span
            >
            <i
              v-show="!row.pai_1"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row,2)
              "
            />
          </template>  
        </el-table-column>
        <el-table-column prop="jobLevelName" label="级别">
           <template slot-scope="{row}">
            <el-select v-model="row.jobLevel"  @change="S_jobLevel(row,2)">
              <el-option v-for="(item,index) in $store.state.config.userJobLevel"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
            
          </template>
        </el-table-column>
        <el-table-column prop="cityName" label="城市">
          <template slot-scope="{ row }">
            <span>{{row.provinceName}}</span>-<span>{{row.cityName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="活动" width="210">
          <template slot-scope="{ row }">
            <el-tag
              type="info"
              size="mini"
              effect="dark"
              v-for="item in !!row.activityName&&row.activityName.split(',')"
              :key="item"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                v-model="row.remark"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row, 2)"
              />
            </div>
            <span
              v-show="!row.pai"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row, 2)
              "
            >
              {{ row.remark }}</span
            >
            <i
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row, 2)
              "
            />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="activityName" label="活动"> </el-table-column> -->
        <el-table-column prop="waId" label="小助手">
          <template slot-scope="{ row }">
            <el-select
              v-model="row.waId"
              placeholder="小助手"
              multiple
              :multiple-limit="3"
              size="medium"
              @change="chagne(row)"
            >
              <el-option
                :label="item.name"
                :value="item.id + ''"
                v-for="(item, index) in $store.state.NormalList"
                :key="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="ew">
            <router-link :to="'/user_guest_edit?id=' + ew.row.id">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="mini"
                @click="edit(ew.row.id)"
                class="skyblue"
                v-show="$store.state.btn_if.indexOf('user_guest_edit') != -1"
                >编辑</el-button
              >
            </router-link>

            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.id, 1)"
              class="red"
              v-show="$store.state.btn_if.indexOf('user_guest_del') != -1"
              >删除</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-camera"
              @click="information(ew.row)"
              class="green"
              v-show="$store.state.btn_if.indexOf('user_guest_show') != -1"
              >查看信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange2"
        :current-page="currentPage2"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total2"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="" :visible.sync="phone_data" width="80%" @close="">
      <el-table
        :data="phonelist"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="realName" label="姓名"> 
           <template slot-scope="{ row }">
            {{ row.realName }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="desc" label="简介"> </el-table-column> -->
        <el-table-column prop="company" label="公司"> </el-table-column>
        <!-- <el-table-column prop="job" label="职务对外名称"> </el-table-column> -->
        <el-table-column prop="phone" label="手机号" width="130">
          <template slot-scope="{ row }">
            {{ row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="130">
          <template slot-scope="{ row }">
            <!-- {{ row.type | Ftype }} -->
            <el-select v-model="row.type"  multiple @change="Stype(row,3)">
              <el-option v-for="(item,i) in $store.state.config.type"
                :key="i"
                :label="item"
                :value="i">
              </el-option>
            </el-select>
            
          </template>
        </el-table-column>
        <el-table-column prop="businessName" label="行业" width="130">
          <template slot-scope="{ row }">
            <el-select placeholder="行业" v-model="row.business"  @change="Sbusiness(row,3)" multiple :multiple-limit='3'>
            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.business"
              :key="index"
            ></el-option>
          </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="job" label="职位">
           <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai_1"
                v-model="row.job"
                size="small"
                class="id"
                :autofocus="row.pai_1"
                @blur="handleInputBlur_1(row,3)"
              />
            </div>
            <span
              v-show="!row.pai_1"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row,3)
              "
            >
              {{ row.job }}</span
            >
            <i
              v-show="!row.pai_1"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput_1(row,3)
              "
            />
          </template>  
        </el-table-column>
        <el-table-column prop="jobLevelName" label="级别">
           <template slot-scope="{row}">
            <el-select v-model="row.jobLevel"  @change="S_jobLevel(row,3)">
              <el-option v-for="(item,index) in $store.state.config.userJobLevel"
                :key="index"
                :label="item"
                :value="index*1">
              </el-option>
            </el-select>
            
          </template>
           </el-table-column>
        <el-table-column prop="cityName" label="城市">
          <template slot-scope="{ row }">
             <span>{{row.provinceName}}</span>-<span>{{row.cityName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="活动" width="210">
            <template slot-scope="{ row }">
            <el-tag
              type="info"
              size="mini"
              effect="dark"
              v-for="item in !!row.activityName&&row.activityName.split(',')"
              :key="item"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        
        <el-table-column prop="remark" label="备注">
          <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                v-model="row.remark"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row, 3)"
              />
            </div>
            <span
              v-show="!row.pai"
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row, 3)
              "
            >
              {{ row.remark }}</span
            >
            <i
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_guest_remark') != -1 &&
                  showinput(row, 3)
              "
            />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="activityName" label="活动"> </el-table-column> -->
        <el-table-column prop="waId" label="小助手" width="120">
          <template slot-scope="{ row }">
            <el-select
              v-model="row.waId"
              placeholder="小助手"
              multiple
              :multiple-limit="3"
              size="medium"
              @change="chagne(row, 3)"
            >
              <el-option
                :label="item.name"
                :value="item.id + ''"
                v-for="(item, index) in $store.state.NormalList"
                :key="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="时间"> </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="ew">
            <router-link :to="'/user_guest_edit?id=' + ew.row.id">
              <el-button
                type="text"
                icon="el-icon-edit"
                size="mini"
                @click="edit(ew.row.id)"
                class="skyblue"
                v-show="$store.state.btn_if.indexOf('user_guest_edit') != -1"
                >编辑</el-button
              >
            </router-link>
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.id, 3, ew.row)"
              class="red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- <el-dialog title="报告修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form_1.title"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form_1.desc"></el-input>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form_1.source"></el-input>
        </el-form-item>
        <el-form-item label="地区">
          <el-select v-model="form_1.areaCode" placeholder="请选择">
            <el-option
              v-for="item in area.reportArea"
              :key="item.id"导入
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看方式">
          <el-select v-model="form_1.viewType" placeholder="请选择">
            <el-option label="查看" :value="1"> </el-option>
            <el-option label="在线下载" :value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业">
          <el-select v-model="form_1.businessId" placeholder="请选择">
            <el-option
              v-for="item in area.reportBusiness"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action='this.$store.getters.imgUrl'
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
          >
            <img v-if="form_1.banner" :src="form_1.banner" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容"> -->
    <!-- <el-input v-model="form.content" type="textarea"></el-input> -->
    <!-- <quill-editor
            ref="myQuillEditor"
            v-model="form_1.content"
            :options="quillOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisiblexui = false
            form_1 = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog> -->
    <el-dialog title="信息" :visible.sync="showinfo" width="40%" @close="">
      <el-form
        :model="form_show"
        ref="form"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="手机号">
          <!-- <el-input v-model="form_show.phone"></el-input> -->
          <span>{{form_show.phone}}</span>
        </el-form-item>
        <el-form-item label="邮箱">
          <!-- <el-input v-model="form_show.email"></el-input> -->
          <span>{{form_show.email}}</span>
        </el-form-item>
        <el-form-item label="企业邮箱">
          <!-- <el-input v-model="form_show.businessEmail"></el-input> -->
          <span>{{form_show.businessEmail}}</span>
        </el-form-item>
        <el-form-item label="微信">
          <!-- <el-input v-model="form_show.wechat"></el-input> -->
          <span>{{form_show.wechat}}</span>
        </el-form-item>
        <el-form-item label="公司地址">
          <!-- <el-input v-model="form_show.address"></el-input> -->
          <span>{{form_show.address}}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
let that

import quillConfig from '../../quill'
import $ from 'jquery'
// import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
import {editInfo} from '../../api/upload.model.js'
export default {
  beforeCreate() {
    that = this
  },
  data() {
    return {
      phonelist: [],
      total_form:{
      allTotal:0,
      existPhoneTotal:0,
      },
      proof: false,
      reveal: true,
      table: [],
      impEX: '导入Excel',
      dioA: false,
      showinfo: false,
      date: [],
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'report'
      },
      quillOption: quillConfig,
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        waId: '',
        business: '',
        city: '',
        type: ''
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: [],
      p_arr: [],
      c_arr: [],
      total2: 1,
      currentPage2: 1,
      phone_data: false,
      state_a: false
    }
  },
  updated() {
    //   console.log(this.form);
  },
  components: {
    Show: show
  },
  methods: {
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        keyword: '',
        waId: '',
        business: '',
        city: '',
        type: ''
        // order: ''
      }
      this.getlist()
    },
    handleCurrentChange2(e) {
      this.currentPage2 = e
      this.get_data()
    },
    showinput(row, val) {
      row.pai = true
      if (val == 2) {
        this.table = [...this.table]
      } else if (val == 3) {
        this.phonelist = [...this.phonelist]
      } else {
        this.list = [...this.list]
      }
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
    },
    showinput_1(row, val){
      row.pai_1 = true
      if (val == 2) {
        this.table = [...this.table]
      } else if (val == 3) {
        this.phonelist = [...this.phonelist]
      } else {
        this.list = [...this.list]
      }
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
    },
    handleInputBlur(row, val) {
      this.$http
        .post('/admin/UserGuest/editRemark', row)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            if (val == 2) {
              this.get_data()
            } else if (val == 3) {
              this.getphoneid(row)
            } else {
              this.getlist()
            }
          } else {
            this.$message.error(res.message)
          }
        })
    },
   async Stype(row,val){

      const res= await editInfo({
        ...row,
        type:row.type.toString()
      })
      if(res.errorCode==200){
      this.$message.success(res.message)
      if (val == 2) {
              this.get_data()
            } else if (val == 3) {
              this.getphoneid(row)
            } else {
              this.getlist()
            }
      }
    },
   async Sbusiness(row,val){
     const res =await editInfo({...row,
     business: row.business.join(',')
     })
     if(res.errorCode==200){
      this.$message.success(res.message)
      if (val == 2) {
              this.get_data()
            } else if (val == 3) {
              this.getphoneid(row)
            } else {
              this.getlist()
            }
      
      }
    },
    handleInputBlur_1(row,val){
        this.$http
        .post('/admin/UserGuest/editInfo', row)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            if (val == 2) {
              this.get_data()
            } else if (val == 3) {
              this.getphoneid(row)
            } else {
              this.getlist()
            }
          } else {
            this.$message.error(res.message)
          }
        })
    },
    show_ex(row) {
      console.log(1)
      this.phone_data = true
      setTimeout(() => {
        this.getphoneid(row)
      }, 200)
    },
    async getphone(row) {
      // var that=this
      console.log(row)
      const { data: res } = await this.$http.get(
        '/admin/UserGuest/getListByPhone',
        {
          params: { phone: row.phone }
        }
      )

      if (res.errorCode == 200) {
        // Vue.set(this.phonelist, index, res.data.list)
        this.phonelist = [...res.data.list]
        this.phonelist.forEach(item => {
          if (item.waId.length > 0) {
            item.waId = item.waId.split(',')
          }
        })
        // this.data.phonelist = res.data.list
        // that.$set('phonelist',res.data.list)
        // console.log(this.phonelist);
      } else {
        this.$message.error(res.message)
      }
    },
    show_s() {
      this.reveal = !this.reveal
      this.get_data()
    },
    async get_data() {
      const { data: res } = await this.$http.get(
        '/admin/UserGuest/getRedundantList',
        {
          params: {
            page: this.currentPage2,
            pageSize: 10
          }
        }
      )
      if (res.errorCode == 200) {
        this.table = res.data.list
        this.total2 = res.data.totalCount
        this.table.forEach(item => {
          if (item.waId.length > 0) {
            item.waId = item.waId.split(',')
          }
          if(item.business.trim().length>0){
                item.business = item.business.split(',')
              }else{
                item.business=[]
              }
           if(item.type==0){
             item.type=""
            }else{
        
                item.type = item.type.toString().split(',')
              }
           if(item.jobLevel==0){
                item.jobLevel=""
           }
        })
      } else {
        this.$message.error(res.message)
      }
    },
    Import() {
      var that = this
      if (this.impEX == '导入Excel') {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = '.xlsx'

        input.click()
        input.onchange = function() {
          // console.log(this.files[0])
          var file = this.files[0]
          var formData = new FormData()
          formData.append('file', file)

          that.$http
            .post('/admin/UserGuest/importExcel', formData)
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                that.$message.success(res.message)
              } else {
                that.$message.error(res.message)
              }
              that.impEX = '导入Excel'
            })

          that.impEX = '导入中'
        }
      }
    },
    async getp() {
      const jj = await this.$store.dispatch('getProvince')
      this.p_arr = jj.data
    },
    async getc(val) {
      this.form_2.city = ''

      const jj = await this.$store.dispatch('getCity', val)
      if (jj.errorCode == 200) {
        this.c_arr = jj.data
      } else {
        this.$message.error(res.message)
      }
    },
    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.getlist()
      this.reveal=true
    },
    check() {
      console.log(1)
    },
    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserGuest/getById?id', { params: { id: row.id } })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    datum(row) {
      location.href = this.baseurl + this.yu + row.document
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },

    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.form_2.page = e
      this.getlist()
    },
    getlist() {
      this.$http
        .get('/admin/UserGuest/getList', {
          params: {
            ...this.form_2
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            // console.log(res)
            this.list = res.data.list
            this.list.forEach(item => {
              if (item.waId.length > 0) {
                item.waId = item.waId.split(',')
              }
              if(item.business.trim().length>0){
                item.business = item.business.split(',')
              }else{
                item.business=[]
              }
              // if(item.business.trim().length>0)
              if(item.type==0){
                item.type = ""
              }else{
        
                item.type = item.type.toString().split(',')
              }
              if(item.jobLevel==0){
                item.jobLevel=""
              }
            })
            console.log(this.list)
            this.total = res.data.totalCount
            this.total_form.allTotal=res.data.allTotal?res.data.allTotal:0
            this.total_form.existPhoneTotal=res.data.existPhoneTotal
            // this.getarea()
            this.list.forEach(item => {
              this.pai.push({ id: item.id, pai: false })
            })
            // this.getea()
          } else {
            this.$message.error(res.message)
            
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    async beforedown() {
      // const phone = this.$cookies.get('phone')
      const phone="15080329619"
      if (!!!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          this.proof = true
          const { data: res } = await this.$http.get('/admin/UserGuest/exportExcel', {
            params: {
              ...this.form_2,
              ...this.form_3
            }
          })
          // console.log(1);
          this.btn = true

          // console.log(msg.data.url);
          // let url = window.URL.createObjectURL(msg.data) //表示一个指定的file对象或Blob对象
          // console.log(url,"看一下这是啥")
          // let a = document.createElement('a')
          // document.body.appendChild(a)
          // console.log(msg.data)
          // let url = msg.data.url
          // console.log(url)
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          } else {
            this.$message.error(res.message)
          }
          // a.href = url
          // a.download //命名下载名称
          // a.click() //点击触发下载
          // window.URL.revokeObjectURL(url)
          // this.dioA = false
        }
      }
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      var qq = document.querySelector('.v-modal')
      if (qq) {
        this.phone_data = false
      }
    },
    S_jobLevel(row,val){
      this.$http.post('/admin/UserGuest/editInfo',{id:row.id,jobLevel:row.jobLevel}).then(({data:res})=>{
        if(res.errorCode==200){
        this.$message.success(res.message)
        if(val==2){
          this.get_data()
        }else if(val==3){
          this.getphoneid(row)
        }else{
          this.getlist()
        }
        }else{
        this.$message.error(res.message)
        }
      })
    },
    open(id, val, row) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/UserGuest/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                if (val == 1) {
                  this.getlist()
                } else {
                  this.get_data()
                  this.getlist()
                  this.getphoneid(row)
                }
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    getphoneid(val) {
      this.$http
        .get('/admin/UserGuest/getListById', {
          params: {
            id: val.id
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.phonelist = res.data.list
            this.phonelist.forEach(item => {
              if (item.waId.length > 0) {
                item.waId = item.waId.split(',')
              }
               if(item.business.trim().length>0){
                item.business = item.business.split(',')
              }else{
                item.business=[]
              }
              if(item.type==0){
                item.type=""
              }else{
        
                item.type = item.type.toString().split(',')
              }
              if(item.jobLevel==0){
                item.jobLevel=""
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
    },
    unfold(val) {
      this.phone_data = true
      this.getphoneid(val)
    },
    show(id, el) {
      this.$http
        .post('/admin/Report/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    chagne(row, val) {
      this.$http
        .post('/admin/UserGuest/editService', {
          ...row,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            if (val == 3) {
              this.getphoneid(row)
            } else {
              this.getlist()
            }
          } else {
            this.$message.error(res.message)
            if (val == 3) {
              this.getphoneid()
            } else {
              this.getlist()
            }
          }
        })
    }
  },
  created() {
    // this.getconfig()

    this.getlist()
    sessionStorage.setItem('action', 'report')
    this.getp()
  },
  mounted() {
    setInterval(() => {
      var ifs = JSON.parse(sessionStorage.getItem('adf'))
      // console.log(ifs)
      if (ifs) {
        this.phone_data = !this.phone_data
        sessionStorage.setItem('adf', false)
        // console.log(this.$store.state.Did)
        this.getphoneid({ id: this.$store.state.Did })
        this.getlist()
        this.get_data()
      } else {
        if (this.$store.state.counts < 1) {
          this.getlist()
          this.get_data()
          this.$store.state.counts++
        }
      }
    }, 500)
  },
  filters: {
    // Ftype(val) {
    //   return that.$store.state.config.user_type[val]
    // },

    getarea: function(value) {
      // console.log(that.area.reportArea,value);
      if (that.area.reportArea) {
        var reportArea = that.area.reportArea[value]
        return reportArea
      }
    },
    getbu(value) {
      if (that.area.reportBusiness) {
        var reportBusiness = that.area.reportBusiness[value]
      }
      return reportBusiness
    },
    getviewType(val) {
      if (val == 1) {
        return '在线查看'
      } else {
        return '下载'
      }
    }
  },
  computed: {
    // Fbusiness() {
    //   return function(row) {
    //     return (
    //       this.$store.state.config.user_business[row.type] &&
          
    //       this.$store.state.config.user_business[row.type][row.business]
    //     )
    //   }
    // },
    jobLevel() {
      return function(row) {
        return this.$store.state.config.userJobLevel[row.jobLevel]
      }
    },
    QX() {
      return function(val) {
        return this.$store.state.btn_if.indexOf(val) != -1
      }
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    height: 36px;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
.out_sp{
  display: inline-block;
  margin-right: 10px;
}
</style>
